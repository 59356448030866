'use client';

import React, { useCallback, useEffect, useState } from 'react';
import { useMount, useUnmount } from 'react-use';

import cx from 'classnames';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import PropTypes from 'prop-types';

import ButtonCTA from 'components/ui/ButtonCTA';
import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import loadingImage from './images/loading.gif';
import mapLarge from './images/map-large.jpg';

import styles from './ProductFinder.module.scss';

let GLOBAL_DESTINI_LOADED = false;

const ProductFinder = ({ categories }) => {
    const searchParams = useSearchParams();

    const PROD = searchParams.get('PROD');

    const [selected, setSelected] = useState(new Set());
    const [isInit, setInit] = useState(false);
    const [isLoaded, setLoaded] = useState(false);

    const handlePostMessage = useCallback(event => {
        if (/^{"eventCategory":"DESTINI_LOAD"/gi.test(event.data)) {
            setTimeout(() => {
                GLOBAL_DESTINI_LOADED = true;
                setLoaded(true);
            }, 1000);
        }
    }, []);

    useMount(() => {
        window.addEventListener('message', handlePostMessage, false);
    });

    useUnmount(() => {
        window.removeEventListener('message', handlePostMessage);
    });

    // force destini to reload on SPA client route
    useEffect(() => {
        if (
            isInit &&
            GLOBAL_DESTINI_LOADED &&
            typeof window.loadIframe === 'function'
        ) {
            window.loadIframe(); // destini global function, ew
        }
    }, [isInit]);

    const handleClick = useCallback(
        item => () => {
            if (selected.has(item.slug)) {
                selected.delete(item.slug);
            } else {
                selected.add(item.slug);
            }
            setSelected(new Set(selected));
        },
        [selected]
    );

    const skus = categories
        .filter(category => selected.has(category.slug))
        .reduce((a, o) => {
            a.push(...o.productSkus);
            return a;
        }, []);

    return (
        <>
            {!isLoaded && (
                <div className={styles.overlayBackground}>
                    <Img
                        className={styles.overlayImage}
                        src={mapLarge.src}
                        width={2160}
                        height={1261}
                        priority
                    />
                    <div className={styles.overlay}>
                        {(isInit || PROD) && !isLoaded && (
                            <div className={styles.loading}>
                                <img
                                    className={styles.loadingIcon}
                                    src={loadingImage.src}
                                    alt=""
                                />
                                <Text
                                    as="h1"
                                    className={styles.loadingHeading}
                                    baseTheme={'headingXSmall'}
                                    themes={{
                                        medium: 'headingSmall',
                                        large: 'headingMedium',
                                    }}
                                >
                                    <b>Hang tight.</b> We’re loading all the
                                    Tillamook near you, this may take a moment.
                                </Text>
                            </div>
                        )}
                        <div className={styles.mapIcon}></div>
                        <Text
                            as="h1"
                            className={styles.heading}
                            baseTheme={'displayXSmall'}
                            themes={{
                                medium: 'displaySmall',
                                large: 'displayMedium',
                            }}
                        >
                            What can we
                            <br />
                            help you find?
                        </Text>
                        <div className={styles.options}>
                            {categories.map(item => (
                                <button
                                    key={item.slug}
                                    onClick={handleClick(item)}
                                    className={cx(styles['options__item'], {
                                        [styles['options__item--selected']]:
                                            selected.has(item.slug),
                                    })}
                                >
                                    <Img
                                        src={item?.thumbnail?.url}
                                        customSources={[
                                            {
                                                breakpoint: 768,
                                                src: item?.thumbnail?.url,
                                                imageWidth: 200,
                                            },
                                            {
                                                src: item?.thumbnail?.url,
                                                imageWidth: 140,
                                            },
                                        ]}
                                        width={item?.thumbnail?.size?.width}
                                        height={item?.thumbnail?.size?.height}
                                    />
                                    <Text
                                        baseTheme={'bodyXSmall'}
                                        themes={{ large: 'bodySmall' }}
                                    >
                                        {item.navLabel || item.title}
                                    </Text>
                                </button>
                            ))}
                        </div>
                        <div className={styles.actions}>
                            <ButtonCTA
                                text="Search"
                                style="filled-blue-ocean"
                                onClick={() => setInit(true)}
                            />
                            <ButtonCTA
                                text="Skip"
                                style="outlined-blue-blue"
                                onClick={() => {
                                    setSelected(new Set());
                                    setInit(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {(isInit || PROD) && (
                <Script
                    src={`//destinilocators.com/tillamook/site/install/?PROD=${skus.join(
                        ','
                    )}`}
                />
            )}
            <div
                className={cx(styles.iframeContainer, {
                    [styles['iframeContainer--loaded']]: isLoaded,
                })}
                id="destini_container"
            ></div>
        </>
    );
};

ProductFinder.propTypes = {
    categories: PropTypes.any,
    markers: PropTypes.object,
};

export default ProductFinder;
